.gi-side-select-tabs {
  position: relative;
}
.gi-side-select-tabs .gi-side-select-tabs-content {
  background: var(--background-color);
  border-right: 1px solid var(--border-color);
  position: relative;
  height: 100%;
  overflow: hidden;
}
.gi-side-select-tabs .gi-side-select-tabs-content .content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 12px;
}
.gi-side-select-tabs .gi-side-select-tabs-content .content-title {
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}
.gi-side-select-tabs .gi-side-select-tabs-content .content-title .ant-select .ant-select-selection-item,
.gi-side-select-tabs .gi-side-select-tabs-content .content-title .ant-select .ant-select-arrow {
  font-weight: bold;
}
.gi-side-select-tabs .gi-side-select-tabs-content .content-title .anticon-question-circle {
  font-size: 14px;
  cursor: pointer;
}
.gi-side-select-tabs .gi-side-select-tabs-content .content-detail {
  padding: 12px 4px;
  flex: 1 1;
  overflow: hidden;
}
