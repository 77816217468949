.community-detection-wrapper .top-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.community-detection-wrapper .community-detection-title {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.community-detection-wrapper .community-detection-algo-radio {
  margin-top: 8px;
  width: 100%;
}
.community-detection-wrapper .community-detection-algo-radio .community-detection-algo-title {
  width: 100%;
}
.community-detection-wrapper .community-detection-algo-radio .community-detection-algo-title-name {
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
}
.community-detection-wrapper .community-detection-algo-radio .community-detection-algo-title-tip {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
}
.community-detection-wrapper .community-detection-algo-body {
  display: inline-flex;
  margin-left: 30px;
  font-size: 14px;
  line-height: 52px;
  color: rgba(0, 0, 0, 0.85);
}
.community-detection-wrapper .community-detection-result-wrapper {
  margin: 20px 8px;
}
.community-detection-wrapper .community-detection-result-title {
  display: block;
  float: left;
  font-family: PingFangSC-Medium;
  color: rgba(0, 0, 0, 0.85);
}
.community-detection-wrapper .community-detection-graph-container {
  height: 302px;
  border: 1px solid #eee;
}
.community-detection-wrapper .community-detection-table-wrapper {
  clear: both;
  padding-top: 10px;
}
