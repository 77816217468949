.expression-group {
  display: flex;
}
.expression-group .ant-form-item {
  margin: 0px;
}
.expression-group .expressionGroupContainer {
  padding-right: 24px;
  border-right: 2px solid #2e55e0;
}
.expression-group .expressionGroupContainer .switch-button-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -20px;
}
.expression-group .expressionGroupContainer .addExpressionButton {
  width: 100%;
  margin: 8px 0;
}
.expression-group .expressionGroupContainer .ant-row .ant-form-item {
  margin: 0px;
}
