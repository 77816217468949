.gi-subGraph-layout .site-collapse-custom-panel {
  margin: 6px 0px;
  background: var(--background-color);
  border: 1px solid var(--border-color);
}
.gi-subGraph-layout .custom-item {
  display: flex;
  align-items: center;
}
.gi-subGraph-layout .custom-item :nth-child(2) {
  flex: 1 1;
}
