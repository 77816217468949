.gi-loading {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
}
.gi-loading .ant-spin.ant-spin-spinning {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
