.assets-list-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
}
.assets-list-container .ant-tree {
  width: 220px;
  margin-right: 12px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 12px;
}
.assets-list-container-iframe {
  width: 100%;
  overflow: auto;
  position: relative;
  background: var(--background-color);
  padding: 24px;
  border-radius: 8px;
}
