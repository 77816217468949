.gi-rich-container {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: block;
}
.gi-rich-container .gi-rich-container-navbar {
  padding: 0px 20px;
  position: relative;
  height: 54px;
  width: 100%;
  background: var(--background-color);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.gi-rich-container .gi-rich-container-navbar .navbar-back {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gi-rich-container .gi-rich-container-navbar .navbar-setting {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gi-rich-container .gi-rich-container-toolbar {
  position: relative;
  font-size: 12px;
  height: 44px;
  width: 100%;
  background: #f3f5f9;
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: 0px 12px;
}
.gi-rich-container .gi-rich-container-toolbar .toolbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gi-rich-container .gi-rich-container-toolbar .operator-toolbar-overlay {
  position: absolute;
  left: 145px;
  width: 85%;
  height: 43px;
  z-index: 9;
}
.gi-rich-container .gi-rich-container-content {
  position: absolute;
  bottom: 0px;
  top: 98px;
  left: 0px;
  right: 0px;
  display: flex;
}
.gi-rich-container .gi-rich-container-content.has-sheet {
  top: 138px;
}
.gi-rich-container .gi-rich-container-content .gi-rich-container-side {
  height: 100%;
  background: var(--background-color);
}
.gi-rich-container .gi-rich-container-content .gi-rich-container-canvas {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
}
