.gi-group-container .gi-style-form {
  height: 100%;
}
.gi-group-container .gi-style-form .gi-style-form-list {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
.gi-group-container .gi-style-form .gi-style-form-list .gi-sidebar-collapse {
  height: calc(100% - 40px);
  overflow: auto;
  background-color: rgba(0, 0, 0, 0);
}
.gi-group-container .gi-style-form .gi-style-form-list .gi-sidebar-collapse::-webkit-scrollbar {
  display: none;
}
.gi-group-container .gi-style-form .gi-style-form-list .gi-style-form-btn-group {
  padding: 0 8px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.gi-group-container .gi-style-form .gi-style-form-list .gi-style-form-btn-group > :first-child {
  flex-grow: 1;
}
.gi-group-container .gi-style-form .ant-form-item {
  height: 100%;
}
.gi-group-container .gi-style-form .ant-form-item > .ant-form-item-row {
  height: 100%;
}
.gi-group-container .gi-style-form .ant-form-item > .ant-form-item-row > .ant-form-item-control {
  height: 100%;
}
.gi-group-container .gi-style-form .ant-form-item > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input {
  height: 100%;
}
.gi-group-container .gi-style-form .ant-form-item > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content {
  height: 100%;
}
.gi-group-container .ant-formily-item {
  margin-bottom: 12px;
}
.gi-group-container .gi-group-container-panel {
  margin: 8px;
  padding: 4px 12px !important;
  background: #fff;
  background: var(--background-color, #fff);
  border-radius: 8px !important;
  box-shadow: -1px -1px 4px 0 hsla(0, 0%, 87.5%, 0.5), -2px 2px 4px 0 hsla(0, 0%, 95.7%, 0.5), 2px 3px 8px 2px hsla(0, 0%, 59.2%, 0.05);
  box-shadow: var(--box-shadow-pannel, -1px -1px 4px 0 hsla(0, 0%, 87.5%, 0.5), -2px 2px 4px 0 hsla(0, 0%, 95.7%, 0.5), 2px 3px 8px 2px hsla(0, 0%, 59.2%, 0.05));
}
.gi-group-container .gi-group-container-panel .ant-collapse-content-box {
  padding: 0px 8px !important;
}
.gi-group-container .gi-group-container-panel .gi-assets-elements-advance-panel {
  box-shadow: -1px -1px 4px 0 hsla(0, 0%, 87.5%, 0.5), -2px 2px 4px 0 hsla(0, 0%, 95.7%, 0.5), 2px 3px 8px 2px hsla(0, 0%, 59.2%, 0.05);
  box-shadow: var(--box-shadow-pannel, -1px -1px 4px 0 hsla(0, 0%, 87.5%, 0.5), -2px 2px 4px 0 hsla(0, 0%, 95.7%, 0.5), 2px 3px 8px 2px hsla(0, 0%, 59.2%, 0.05));
}
.gi-group-container .gi-group-container-panel .ant-collapse-header {
  padding: 6px 8px !important;
  line-height: 32px !important;
}
.gi-group-container .gi-group-container-panel .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 6px;
}
.gi-group-container .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  height: 32px;
  line-height: 32px;
}
.gi-group-container .left > .ant-row.ant-form-item {
  margin: 0px;
}
.gi-group-container .gi-group-container-panel-header {
  margin: 0px;
}
.expression-group {
  display: flex;
}
.expression-group .switch-button-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -20px;
}
