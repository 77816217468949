.gi-layout {
  background-color: var(--layout-background-color);
  background-image: var(--layout-background);
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 1140px;
}
.gi-layout > header {
  background: transparent !important;
}
.gi-layout__container {
  background: transparent !important;
  padding: 12px 48px 0px 48px;
  height: calc(100vh - 100px);
}
.gi-layout__side {
  border: none !important;
  background: transparent !important;
  color: var(--text-color) !important;
}
.gi-layout__side .ant-menu-item:hover {
  color: var(--primary-color) !important;
}
.gi-layout__side .ant-menu-item-selected {
  border-radius: 6px;
  background: var(--primary-color-opacity-1) !important;
  color: var(--primary-color) !important;
  transition: all 0.3 ease;
}
