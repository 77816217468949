.nodes-similarity-wrapper {
  margin: 8px;
}
.nodes-similarity-wrapper .top-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nodes-similarity-wrapper .nodes-similarity-title {
  font-size: 14px;
}
.nodes-similarity-wrapper .nodes-similarity-operations {
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: baseline;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}
.nodes-similarity-wrapper .nodes-similarity-algo-radio {
  margin-top: 8px;
  width: 100%;
}
.nodes-similarity-wrapper .nodes-similarity-algo-radio .nodes-similarity-algo-title {
  width: 100%;
}
.nodes-similarity-wrapper .nodes-similarity-algo-radio .nodes-similarity-algo-title-name {
  line-height: 22px;
}
.nodes-similarity-wrapper .nodes-similarity-algo-radio .nodes-similarity-algo-title-tip {
  margin-left: 8px;
}
.nodes-similarity-wrapper .nodes-similarity-algo-body {
  display: inline-flex;
  margin-left: 30px;
  font-size: 14px;
  line-height: 52px;
}
.nodes-similarity-wrapper .nodes-similarity-result-wrapper {
  margin: 20px 8px;
}
.nodes-similarity-wrapper .nodes-similarity-result-title {
  display: block;
  float: left;
}
.nodes-similarity-wrapper .nodes-similarity-graph-container {
  height: 302px;
  border: 1px solid #eee;
}
.nodes-similarity-wrapper .nodes-similarity-table-wrapper {
  clear: both;
  padding-top: 10px;
}
.nodes-similarity-wrapper .nodes-similarity-table-wrapper .nodes-similarity-statistic {
  margin-bottom: 10px;
}
.nodes-similarity-wrapper .nodes-similarity-table-wrapper .nodes-similarity-statistic-value {
  margin-left: 2px;
}
