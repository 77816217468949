.gi-l7-botton-container {
  position: absolute;
  top: 50px;
  left: 9px;
  background: #ffffff;
  border: 1px solid #d4d8da;
  border-radius: 4px;
}
.gi-l7-botton-container .gi-l7-botton {
  display: block;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  margin: 1px;
  padding: 0;
  border: 0;
  outline: none;
}
.mapboxgl-map {
  background: var(--background-color) !important;
}
