.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--text-color-2);
  background-color: var(--background-color);
  border-radius: 8px;
}
.content .content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
}
.content .content-header > div {
  flex: 1 1;
}
.content .content-header .content-header-field {
  font-size: 11px;
  text-align: left;
}
.content .content-header .content-header-field span {
  margin-right: 6px;
}
.content .content-header .content-header-time {
  color: var(--text-color);
  font-weight: 500;
  font-size: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.content .chart {
  height: calc(100% - 80px);
}
.content .content-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
}
.content .content-btn > div {
  margin: 0 5px;
}
