.project-card {
  width: 356px;
  height: 100px;
  border-radius: 8px;
  background-color: var(--background-color-transparent);
  cursor: pointer;
}
.project-card .content-wrapper {
  display: flex;
}
.project-card .content-wrapper .cover {
  display: flex;
  flex-basis: 100px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.project-card .content-wrapper .content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
  margin-left: 8px;
}
.project-card .content-wrapper .content .title {
  font-weight: 600;
  font-size: 16px;
  color: var(--text-color);
}
.project-card .content-wrapper .content .desc {
  overflow: hidden;
  color: var(--text-color-2);
  font-size: 12px;
  text-overflow: ellipsis;
}
.project-card .content-wrapper .content .time {
  font-size: 12px;
  color: var(--text-color-2);
}
.project-card .content-wrapper .content .expired {
  font-size: 12px;
  color: var(--text-warning-color);
}
.project-card .divider {
  margin-top: 8px;
  margin-bottom: 0;
}
.project-card .footer {
  padding: 8px 0;
  justify-content: space-between;
  display: flex;
}
.project-card .footer .action {
  color: var(--text-color);
  height: 32px;
  line-height: 32px;
  margin-top: -4px;
  margin-bottom: 0;
}
.project-card .footer .action:hover {
  color: var(--primary-color);
}
.project-card .footer .divider-vertical {
  height: 26px;
}
.project-card .extra-top-right {
  position: absolute;
  right: 12px;
  top: 14px;
}
.ant-radio-button-wrapper.gi-workspace-temp {
  width: 140px !important;
  height: 140px !important;
  margin: 8px !important;
  padding: 8px !important;
  text-align: center;
}
