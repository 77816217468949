.nodes-clustering-wrapper .top-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nodes-clustering-wrapper .nodes-clustering-title {
  font-size: 14px;
}
.nodes-clustering-wrapper .nodes-clustering-algo-radio {
  margin-top: 8px;
  width: 100%;
}
.nodes-clustering-wrapper .nodes-clustering-algo-radio .nodes-clustering-algo-title {
  width: 100%;
}
.nodes-clustering-wrapper .nodes-clustering-algo-radio .nodes-clustering-algo-title-name {
  line-height: 22px;
}
.nodes-clustering-wrapper .nodes-clustering-algo-radio .nodes-clustering-algo-title-tip {
  margin-left: 8px;
  color: var(--text-color-2);
}
.nodes-clustering-wrapper .nodes-clustering-algo-body {
  font-size: 14px;
  line-height: 52px;
}
.nodes-clustering-wrapper .nodes-clustering-result-wrapper {
  margin: 20px 8px;
}
.nodes-clustering-wrapper .nodes-clustering-result-title {
  display: block;
  float: left;
}
.nodes-clustering-wrapper .nodes-clustering-graph-container {
  height: 302px;
  border: 1px solid #eee;
}
.nodes-clustering-wrapper .nodes-clustering-table-wrapper {
  clear: both;
  padding-top: 10px;
}
