.gi-info-detection .ant-list-item {
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  margin-top: 2px;
  height: 46px;
  width: 100%;
  cursor: pointer;
}
.gi-info-detection .ant-list-item:hover {
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
}
