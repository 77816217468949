.gi-navbar-container {
  background: var(--background-color) !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 40px;
}
.gi-navbar-container a {
  color: var(--text-color);
  font-size: 14px;
}
.gi-navbar-container a:hover {
  /* border: 1px solid red; */
  transition: all 0.3s ease;
  color: var(--primary-color);
}
.gi-navbar-container .active a {
  padding: 8px 10px;
  border-radius: 4px;
  background: var(--primary-color-opacity-1);
  color: var(--primary-color);
  font-size: 14px;
  transition: all 0.3s ease;
}
.gi-navbar-container .left {
  display: flex;
  align-items: center;
}
.gi-navbar-container .right {
  display: flex;
  align-items: center;
}
.gi-navbar-container .right button {
  margin-right: 8px;
  border-radius: 4px;
}
.gi-navbar-container .right span:hover {
  color: var(--primary-color);
}
.export-panel {
  height: 100%;
}
.export-panel .card-meta .title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}
.gi-header-github-icon {
  margin-left: 8px;
  min-width: 32px;
  display: inline-flex;
}
.theme-color-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}
