.gi-panel-div {
  color: var(--text-color);
  background: var(--background-color);
  overflow: auto;
}
.gi-panel-div.open.left {
  display: block;
  width: 300px;
  transition: all 0.3s;
}
.gi-panel-div.open.right {
  display: block;
  width: 300px;
  transition: all 0.3s;
}
.gi-panel-div.close.left {
  width: 0px !important;
  transition: all 0.3s;
}
.gi-panel-div.close.right {
  width: 0px !important;
  transition: all 0.3s;
}
.gi-panel-div .header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid var(--border-color-base);
  border-radius: 2px 2px 0 0;
}
.gi-panel-div .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.gi-panel-div .title {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.gi-panel-div .body {
  flex-grow: 1;
  padding: 12px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
