.dialog-header {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.dialog-header .dialog-header-button-group {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}
.dialog-header .dialog-header-button-group .dialog-header-cancel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #f4bb51;
  border-radius: 50%;
  cursor: pointer;
}
.dialog-header .dialog-header-button-group .dialog-header-cancel .cancel-button {
  width: 10px;
  height: 10px;
  background-color: white;
}
.dialog-header .dialog-header-button-group .dialog-header-close .close-button {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
}
.dialog-header .dialog-header-button-group .dialog-header-close .close-button-bar {
  position: absolute;
  top: 8px;
  left: 2px;
  width: 14px;
  height: 2px;
  background-color: #fff;
}
.dialog-header .dialog-header-button-group .dialog-header-close .close-button-bar:first-child {
  transform: rotate(45deg);
}
.dialog-header .dialog-header-button-group .dialog-header-close .close-button-bar:last-child {
  transform: rotate(-45deg);
}
