.upload-panel-section {
  width: 100%;
  height: 275px;
  margin: 10px 0px;
}
.upload-panel-section > span {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}
.upload-panel-section .ant-upload-list {
  display: inline-flex;
  margin: 0 0 10px;
  vertical-align: middle;
}
.dataCheck-panel .fliter-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}
.dataCheck-panel .fliter-group .title {
  width: 64px;
  height: 22px;
  font-size: 16px;
  letter-spacing: 0;
  opacity: 0.85;
}
.dataCheck-panel .ant-card-body {
  margin: 20px 0px;
  padding: 0px;
}
