/** 魔改Select */
.style-panel-assets-select {
  border-radius: 6px;
}
.style-panel-assets-select.ant-select,
.style-panel-assets-select.ant-select:not(.ant-select-customize-input) {
  margin: 8px;
  background: var(--background-color);
  background-color: var(--background-color);
}
.style-panel-assets-select.ant-select .antd-select-focused .ant-select-selector,
.style-panel-assets-select.ant-select:not(.ant-select-customize-input) .antd-select-focused .ant-select-selector {
  width: 318px;
  height: 65px;
  border: none;
  outline: none;
}
.style-panel-assets-select.ant-select .ant-select-selector,
.style-panel-assets-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  width: 318px;
  height: 65px;
  background-color: var(--background-color);
  border: none;
  border-radius: 8px;
  outline: none;
  box-shadow: var(--box-shadow-pannel);
}
.style-panel-assets-select.ant-select .ant-select-selection-item,
.style-panel-assets-select.ant-select:not(.ant-select-customize-input) .ant-select-selection-item {
  line-height: 65px;
}
.style-panel-assets-select-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 16px;
  color: var(--text-color);
  font-size: 16px;
  letter-spacing: 0;
}
