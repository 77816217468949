.gi-uad-layout-tabs .ant-tabs-content {
  height: 100%;
}
.gi-uad-layout-tabs .ant-tabs-content .ant-tabs-tabpane {
  height: 100%;
}
.gi-uad-layout-tabs .ant-tabs-tab {
  padding: 0px;
}
.gi-uad-layout-canvas-wrapper {
  width: 100%;
  margin-top: 16px;
  padding: 8px 0px;
  background: #fff;
}
