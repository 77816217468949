.loop-detection-demo {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.loop-detection-demo-list {
  height: 300px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  padding: 5px !important;
  border-radius: 10px;
}
.loop-detection-demo-list-item {
  padding: 2px 0 2px 0 !important;
  border: none !important;
}
.loop-detection-demo-list-item-message {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  max-width: 70%;
}
.loop-detection-demo-list-item-message .ant-avatar {
  flex-shrink: 0;
}
.loop-detection-demo-list-item-message-content {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
}
.loop-detection-demo-list-item-send {
  justify-content: flex-end !important;
}
.loop-detection-demo-controller {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.loop-detection-demo-controller-btn-group {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
