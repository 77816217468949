.gi-assets-operator-item {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 60px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background 0.3s;
}
.gi-assets-operator-item.gi-assets-operator-item-active {
  background: #eee;
}
.gi-assets-operator-item:hover {
  -webkit-filter: grayscale(0.5);
          filter: grayscale(0.5);
}
.gi-assets-operator-item .gi-assets-operator-item-loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.gi-assets-operator-item .gi-assets-operator-item-title {
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
