.loop-container {
  position: relative;
}
.loop-container .loop-circle {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border: 1px solid #ddd;
  border: 1px solid var(--bordor-color, #ddd);
  border-radius: 100%;
  margin: 10px;
}
