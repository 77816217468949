.gi-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gi-placeholder .image-wrapper {
  overflow: auto;
  width: 100%;
}
.gi-placeholder .image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
