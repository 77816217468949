.editor-toolbar {
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid var(--border-color);
}
.gi-gremlin-query {
  z-index: 3;
  border-radius: 6px;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow-pannel);
  transition: height 0.3s ease;
}
.gi-gremlin-query-config {
  display: block;
  margin: 8px;
}
.gi-gremlin-query-config .gi-gremlin-query-constraint-constraint {
  display: inline-flex;
  width: 100%;
}
.gi-gremlin-query-config .gi-gremlin-query-constraint-constraint .gi-gremlin-query-constraint-label {
  width: 96px;
}
.gi-gremlin-query-constraints-wrapper {
  width: 100%;
}
.gi-gremlin-query-config-tip {
  margin-top: 4px;
  font-size: 10px;
  color: var(--disabled-color);
}
