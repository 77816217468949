.content-wrapper {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.content-wrapper .title-wrapper .title {
  font-family: PingFangSC-Medium;
  font-size: 14px;
}
.content-wrapper .title-wrapper .title-tip {
  margin-left: 8px;
}
.content-wrapper .algo-radio {
  width: 100%;
  margin-top: 18px;
}
.content-wrapper .algo-radio .algo-name {
  line-height: 22px;
}
.content-wrapper .algo-radio .algo-tip {
  margin-left: 8px;
}
.content-wrapper .algo-radio .algo-title {
  width: 100%;
}
.content-wrapper .algo-body {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 14px;
}
.content-wrapper .algo-body-property {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 0;
}
.content-wrapper .algo-body-property .ant-form-item {
  margin-bottom: 0;
}
.calc {
  width: 107px;
  left: calc(100vw - 183px);
}
.calc .calc-tip {
  margin-top: 4px;
  opacity: 0.45;
}
.mapping .mapping-tip {
  margin-top: 4px;
  opacity: 0.45;
}
.button {
  margin-top: 24px;
}
.result-wrapper {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.result-wrapper .result-title {
  font-family: PingFangSC-Medium;
}
.result-wrapper .result-message {
  margin-top: 16px;
}
.result-wrapper .result-statistic {
  margin-top: 16px;
}
.result-wrapper .plot-wrapper {
  margin-top: 24px;
}
.result-wrapper .result-table-property-select {
  margin-left: 4px;
  width: 80px;
}
