.gi-case-flex {
  display: flex;
  flex-direction: row;
  justify-items: center;
  height: 40px;
  line-height: 40px;
}
.gi-case-flex .title {
  margin-right: 12px;
  color: #616161;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0;
}
.gi-case-flex .time,
.gi-case-flex .author {
  padding-right: 12px;
  color: #a7a7a7;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0;
}
.gi-case-flex .video {
  flex: 1 1;
  color: #9faabe;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  text-align: right;
}
