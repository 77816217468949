.icon-input-container {
  width: 100%;
}
.icon-input-container .icon-input-popover-inner-content {
  padding: 0 !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.icon-input-container .icon-input-container-icon-tips {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
}
.gi-icon-picker {
  display: block;
  width: 176px;
}
.gi-icon-picker .gi-icon-picker-menu {
  display: inline-block;
  width: 62px;
  vertical-align: top;
}
.gi-icon-picker .gi-icon-picker-content {
  display: inline-block;
  width: 114px;
  vertical-align: top;
}
