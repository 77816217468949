.gi-filter-panel {
  padding: 10px;
}
.gi-filter-panel .gi-filter-panel-criteria-container {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  margin-top: 15px;
}
.gi-filter-panel .gi-filter-panel-criteria-container .gi-filter-panel-group {
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 0 4px #ddd;
  box-shadow: var(--box-shadow-light, 0 0 4px #ddd);
}
.gi-filter-panel .gi-filter-panel-criteria-container .gi-filter-panel-group .gi-filter-panel-prop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.gi-filter-panel .gi-filter-panel-criteria-container .gi-filter-panel-group .gi-filter-panel-value {
  margin-top: 6px;
}
