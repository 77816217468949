#gi-switch-elements-tab .ant-tabs-nav-list {
  width: 320px;
  padding: 0px 0px;
  background: #f5f5f5;
  background: var(--background-color-2, #f5f5f5);
  border-radius: 4px;
}
#gi-switch-elements-tab .ant-tabs-tab {
  width: 160px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}
#gi-switch-elements-tab .ant-tabs-tab-active {
  color: '#fff';
  background: #3056e3;
  background: var(--primary-color, #3056e3);
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}
#gi-switch-elements-tab .ant-tabs-ink-bar {
  display: none;
}
#gi-switch-elements-tab .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 2px;
}
#gi-switch-elements-tab .ant-tabs-nav-wrap {
  padding: 4px;
}
#gi-switch-elements-tab .tab-title {
  display: block;
  width: 160px;
  text-align: center;
}
#gi-switch-elements-tab .ant-tabs-nav {
  margin: 0px;
  padding: 8px 0px;
}
#gi-switch-elements-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  background: rgba(0, 0, 0, 0.02);
}
