.gi-data-fliter-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}
.gi-data-fliter-group .gi-data-title {
  width: 64px;
  height: 22px;
  font-size: 16px;
  letter-spacing: 0;
  opacity: 0.85;
}
.gi-config-panel-title {
  height: 56px;
  padding: 0px 12px;
  font-size: 18px;
  line-height: 56px;
  letter-spacing: -0.3px;
}
