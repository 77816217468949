.nodeSelectionForm {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  margin-bottom: 24px;
}
.nodeSelectionForm .nodeSelectionFormItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  grid-gap: 10px;
  gap: 10px;
}
.nodeSelectionForm .nodeSelectionFormItem .main {
  width: calc(100% - 25px);
}
.ant-form-vertical .nodeSelectionFromItem {
  align-items: center;
}
