.gi-uad-layout-tabs .ant-tabs-content {
  height: 100%;
}
.gi-uad-layout-tabs .ant-tabs-content .ant-tabs-tabpane {
  height: 100%;
}
.gi-uad-layout-tabs .ant-tabs-tab {
  padding: 0px;
}
.gi-segmented-container-tabs > .appear {
  display: block;
  visibility: visible;
  height: 100%;
}
.gi-segmented-container-tabs > .hidden {
  display: none;
  visibility: hidden;
}
