ul.gi-radio-note {
  display: block;
  list-style: none;
  margin: 0px;
  padding: 0px;
}
ul.gi-radio-note > li {
  list-style: none;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 80px;
  display: inline-block;
  margin-right: 12px;
  color: var(--text-color);
  transition: all 0.3 ease;
}
ul.gi-radio-note > li.active {
  background: var(--primary-color-opacity-1);
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 6px;
}
ul.gi-radio-note > li.active:hover {
  background: var(--primary-color-opacity-1);
}
ul.gi-radio-note > li:hover {
  background: var(--primary-color-opacity-1);
  cursor: pointer;
  border-radius: 6px;
}
ul.gi-radio-note > li .icon {
  text-align: center;
  font-size: 36px;
}
ul.gi-radio-note > li .name {
  text-align: center;
  font-size: 12px;
}
@media screen and (max-width: 1530px) {
  ul.gi-radio-note > li {
    height: 60px;
    width: 60px;
  }
  ul.gi-radio-note > li .icon {
    font-size: 24px;
  }
}
@media screen and (max-width: 1250px) {
  ul.gi-radio-note > li {
    height: 80px;
    width: 80px;
  }
  ul.gi-radio-note > li .icon {
    font-size: 36px;
  }
}
