.graphin-components-contextmenu {
  border-radius: 8px !important;
}
.graphin-components-contextmenu .ant-menu {
  border-radius: 8px;
}
.graphin-components-contextmenu .ant-menu-submenu:hover,
.graphin-components-contextmenu .ant-menu-item:hover {
  background: #fafafa;
}
