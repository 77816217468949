.gi-segmented-tabs {
  height: calc(100% - 56px);
}
.gi-segmented-tabs > .appear {
  display: block;
  visibility: visible;
  height: 100%;
}
.gi-segmented-tabs > .hidden {
  display: none;
  visibility: hidden;
}
