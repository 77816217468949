.gi-assets-container {
  height: calc(100% - 64px);
  padding: 12px 48px 48px 48px;
  overflow-y: scroll;
  background: #fafafa;
}
.gi-assets-container .ant-card-bordered .ant-card-cover {
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  border-bottom: 1px solid #f0f0f0;
}
