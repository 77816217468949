.gi-config-panel {
  height: 100%;
  overflow: auto;
  background: var(--background-color-transparent);
  border-radius: 8px;
}
.gi-config-panel .datav-gui .datav-gui-select .rc-select-selection {
  border-radius: 2px !important;
}
.gi-config-panel .datav-gui .datav-gui-fill {
  border-radius: 2px !important;
}
.gi-config-panel .ant-formily-array-collapse-item {
  border: none;
  background-color: var(--background-color);
}
.gi-config-panel::-webkit-scrollbar {
  display: none;
}
.gi-tab {
  position: absolute;
  top: 40px;
  width: 340px;
  visibility: hidden;
}
.gi-tab.true {
  visibility: visible;
}
.gi-tab.false {
  visibility: hidden;
}
.datav-gui {
  background: #f7f8fa;
  box-shadow: none;
}
.datav-collapse {
  margin: 8px;
  padding: 4px;
  background: var(--background-color);
  border-radius: 8px;
  box-shadow: -1px -1px 4px 0 rgba(223, 223, 223, 0.5), -2px 2px 4px 0 rgba(244, 244, 244, 0.5), 2px 3px 8px 2px rgba(151, 151, 151, 0.05);
}
.datav-collapse-panel-header {
  background: var(--background-color);
}
.datav-gui .datav-gui-collapse .datav-collapse-panel-header::before {
  display: none;
}
.datav-gui-collection-title.ellipsis {
  color: #000;
  font-size: 14px;
  font-family: PingFangSC-Medium;
}
.gi-site-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 8px;
}
.gi-site-collapse .ant-collapse-item {
  margin: 0px 8px !important;
  border-radius: 4px !important;
  box-shadow: var(--box-shadow-pannel) !important;
  background: var(--background-color);
}
.gi-site-collapse .ant-collapse-item .ant-formily-item {
  margin: 4px;
  font-size: 14px;
}
.gi-site-collapse-item .ant-collapse-item {
  margin: 4px 0px !important;
  border-radius: 4px !important;
  box-shadow: var(--box-shadow-pannel) !important;
  background: var(--background-color);
}
.ant-formily-item-label-align-right > .ant-formily-item-label {
  color: var(--text-color);
  justify-content: center;
  width: 74px;
  font-size: 13px;
}
.ant-formily-item-feedback-layout-loose {
  margin-bottom: 8px;
}
