@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.status .ant-badge-status-dot {
  transition: background-color 0.5s ease;
  animation: blink 3s infinite;
}
