.gi-theme-setting {
  width: 100%;
}
.gi-theme-setting .theme-list .ant-card {
  margin-top: 10px;
}
.gi-theme-setting .theme-list .ant-card .ant-card-cover {
  margin: 0;
}
.gi-theme-setting .theme-list .ant-card .ant-card-body {
  display: flex;
  align-content: center;
  justify-content: center;
}
.gi-theme-setting .theme-list .ant-card .ant-card-body .name {
  font-weight: bold;
  font-size: 18px;
}
.gi-theme-setting .add-theme header {
  position: relative;
  margin-bottom: 20px;
}
.gi-theme-setting .add-theme header .title {
  position: absolute;
  left: 50%;
  font-size: 24px;
  transform: translateX(-50%);
}
.theme-style .ant-alert {
  margin-bottom: 10px;
}
.theme-style .ant-alert header {
  margin-bottom: 5px;
}
