.color-input-container {
  width: 100%;
}
.color-input-container .color-input-popover-inner-content {
  padding: 0 !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.color-input-container .color-input-container-color-tips {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #ccc;
  cursor: pointer;
}
