.assistant {
  z-index: 999;
  font-size: 14px;
}
.assistant .dialog-enter {
  transform: scale(0.9);
  opacity: 0;
}
.assistant .dialog-button img {
  -webkit-user-drag: none;
}
.assistant .dialog-enter-active,
.assistant .dialog-button-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.assistant .dialog-exit,
.assistant dialog-button-exit {
  transform: scale(1);
  opacity: 1;
}
.assistant .dialog-exit-active,
.assistant .dialog-button-exit-active {
  transform: scale(0.9);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}
.assistant .dialog-wrapper {
  position: absolute;
  width: 250px;
  height: 400px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.assistant .dialog-wrapper .dialog-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  background: var(--layout-background);
  border-radius: 10px;
}
