ul.graphin-components-toolbar-content {
  background: var(--background-color);
  color: var(--text-color);
  width: 100%;
  padding: 0;
  text-align: center;
  list-style: none;
  box-shadow: var(--box-shadow-base);
  border-radius: 6px;
}
