.gi-style-setting .ant-tabs-tabpane {
  height: calc(100vh - 180px);
}
.gi-style-setting .ant-tabs-nav-list {
  width: 100%;
  padding: 0px 0px;
  background: #fafafa;
  border-radius: 4px;
}
.gi-style-setting .ant-tabs-tab {
  width: 50%;
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: center;
}
.gi-style-setting .ant-tabs-tab-active {
  color: '#fff';
  background: #3056e3;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}
.gi-style-setting .ant-tabs-ink-bar {
  display: none;
}
.gi-style-setting .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 2px;
}
.gi-style-setting .ant-tabs-nav-wrap {
  padding: 0 12px;
}
.gi-style-setting .ant-tabs-nav-wrap::before {
  display: none;
}
.gi-style-setting .tab-title {
  display: block;
  width: 160px;
  text-align: center;
}
.gi-style-setting .ant-tabs-nav {
  margin: 0px;
  padding: 8px 0px;
}
.gi-style-setting .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  background: rgba(0, 0, 0, 0.02);
}
