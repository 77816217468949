.graph-annotation-icon-tooltip {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 8px;
  color: rgba(255, 255, 255, 0.85);
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 4px;
}
.gi-annotation-tag-color-container {
  display: inline-flex;
  padding: 8px 4px;
}
.gi-annotation-tag-color-container .gi-annotation-tag-color {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin: 4px 4px;
  background-color: #fff;
}
.gi-annotation-tag-color-container .gi-annotation-tag-cancel {
  display: inline-flex;
}
