.gi-path-analysis {
  margin: 0px !important;
}
.gi-path-analysis .gi-path-analysis-container {
  padding: 12px 12px 8px 12px;
  border-radius: 4px;
  margin: 8px 0px;
  position: relative;
}
.gi-path-analysis .gi-path-analysis-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  height: 30px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.gi-path-analysis .gi-path-analysis-path-list-container {
  min-height: 450px;
  overflow-y: auto;
}
.gi-filter-rule-container {
  width: 300px;
}
.gi-collapse-container {
  margin: 1px !important;
  padding: 4px 4px !important;
  background: #fff;
  background: var(--background-color, #fff);
  border-radius: 4px !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2) !important;
  box-shadow: var(--box-shadow-base, 0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2)) !important;
}
