.cypher-query-container {
  position: relative;
  padding: 16px;
  height: 100%;
  visibility: hidden;
}
.cypher-query-container .publishButton {
  position: absolute;
  right: 118px;
}
.CodeMirror .CodeMirror-scroll {
  overflow: auto !important;
}
