.gi-table-mode {
  width: 100%;
  height: 100%;
}
.gi-table-mode .ant-tabs {
  height: 100%;
}
.gi-table-mode .ant-tabs .ant-tabs-content {
  height: 100%;
}
.gi-table-mode .ant-tabs-tabpane {
  height: 100%;
  width: 100%;
}
.gi-table-mode:-webkit-full-screen {
  background-color: #fff;
}
.gi-table-mode:fullscreen {
  background-color: #fff;
}
.gi-tablemode-filter-tip {
  color: var(--disabled-color);
}
.gi-tablemode-filter-check-item-list {
  margin-top: 8px;
  max-height: 35vh;
  overflow-y: scroll;
}
