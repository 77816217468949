/* KEYFRAMES */
.spinner-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #1d2630;
}
@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}
/* X-ROTATING BOXES */
.configure-border-1 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 115px;
  padding: 3px;
  background: #fb5b53;
  animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
}
.configure-border-2 {
  left: -115px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 115px;
  padding: 3px;
  background: #3ff9dc;
  transform: rotate(45deg);
  animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
}
.configure-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
}
.loading-text {
  position: absolute;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
