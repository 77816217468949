.gi-group-container-wrapper {
  overflow: hidden;
}
.gi-group-container-wrapper .gi-group-container-side-content {
  background-color: #fff;
  transition: max-width 0.4s;
  border-right: 1px solid #eee;
  transform: translate3d(0px, 0px, 1px);
}
.gi-group-container-wrapper .gi-group-container-side-content > div {
  padding: 20px;
}
.gi-group-container {
  float: left;
  display: flex;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  overflow-y: hidden;
  border: 1px solid #eee;
}
.gi-group-container[data-vertical="true"] .gi-assets-operator-item {
  width: 100%;
}
.gi-group-container[data-vertical="false"] .gi-assets-operator-item {
  height: 100%;
}
.gi-group-container .gi-group-container-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
}
.gi-group-container .gi-group-container-item br {
  display: none;
}
.gi-group-container .gi-group-container-item br + span {
  display: block;
  font-size: 12px !important;
}
.gi-group-container .gi-group-container-item .gi-group-container-driver {
  position: absolute;
  background-color: #ccc;
}
