.content-btn-item {
  width: 32px;
  height: 32px;
  background-color: var(--background-color);
  border-radius: 4px;
  cursor: pointer;
}
.content-btn-item .ant-btn {
  padding-right: 0;
  padding-left: 0;
  color: var(--text-color-2);
}
.content-btn-item span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
.content-btn-item:hover span {
  color: var(--primary-color);
}
