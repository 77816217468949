.gi-container-config-wrapper {
  margin: 12px;
}
.gi-container-config-wrapper .gi-container-config-item {
  display: inline-flex;
  width: 100%;
}
.gi-container-config-wrapper .gi-container-config-item .gi-container-config-label {
  width: 30%;
  line-height: 30px;
}
.gi-container-config-wrapper .gi-container-config-item .gi-container-config-input {
  width: 70%;
}
.gi-container-config-wrapper .gi-container-config-item .gi-container-config-input .gi-container-config-seletor {
  width: 100%;
}
.gi-container-config-wrapper .gi-container-collapse {
  margin-top: 16px;
  background-color: rgba(0, 0, 0, 0);
}
.gi-container-config-wrapper .gi-container-collapse .site-collapse-custom-panel {
  border-radius: 8px;
  margin-bottom: 8px;
}
.gi-container-config-wrapper .gi-container-panel-render-collapse .ant-collapse-content-box {
  padding-top: 0px !important;
}
.gi-container-config-wrapper .gi-render-form {
  display: inline-flex;
  width: 100%;
}
.gi-container-config-wrapper .gi-render-form .gi-render-form-config {
  display: block;
  padding-right: 6px;
}
.gi-container-config-wrapper .gi-render-form .gi-render-form-container {
  display: block;
}
.gi-container-config-wrapper .gi-render-form .gi-render-form-container .ant-formily-item {
  display: block;
}
.gi-container-config-wrapper .gi-render-form .gi-render-form-container .gi-render-form-assets-wrapper {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.gi-container-config-wrapper .gi-render-form .gi-render-form-container .gi-render-form-assets-wrapper .gi-render-form-assets-input-label {
  margin-bottom: 4px;
  font-size: 13px;
  color: var(--text-color);
}
.gi-container-config-wrapper .gi-render-form .gi-render-form-container .gi-render-form-assets-wrapper .gi-render-form-assets-input-label .gi-render-form-assets-selecting-label {
  color: var(--primary-color);
}
.gi-container-config-wrapper .gi-render-form .gi-render-form-container .gi-render-form-assets-wrapper .gi-render-form-assets-input {
  width: 100%;
  height: inherit;
  min-height: 30px;
  border-radius: 6px;
  border: 1px solid #ccc;
  cursor: text;
  padding: 4px 0;
  overflow-y: none;
}
.gi-container-config-wrapper .gi-render-form .gi-render-form-container .gi-render-form-assets-wrapper .gi-render-form-assets-input .gi-render-form-asset-item {
  display: inline-block;
  height: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 23px;
  border-radius: 15px;
  background-color: var(--background-color);
  margin: 6px 4px;
  padding: 4px 8px;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2);
}
.gi-container-config-wrapper .gi-render-form .gi-render-form-container .gi-render-form-assets-wrapper .gi-render-form-assets-input-selecting {
  border: 1px solid var(--primary-color);
}
.gi-assets-center-wrapper {
  position: fixed;
  width: 40%;
  min-width: 500px;
  height: 50vh;
  background-color: var(--background-color-transparent);
  bottom: 0;
  left: 50px;
  border-radius: 8px;
  transition: all 0.3s ease;
  padding: 12px;
}
.gi-container-config-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
  margin: 12px;
  font-size: 18px;
}
.gi-assets-center::-webkit-scrollbar {
  display: none;
}
.gi-assets-center {
  overflow-y: scroll;
  height: calc(100% - 42px);
  padding: 16px;
}
.gi-assets-center .gi-assets-center-assets-cardgroup::-webkit-scrollbar {
  display: none;
}
.gi-assets-center .gi-assets-center-assets-cardgroup {
  overflow-y: scroll;
  height: 90%;
  padding: 0px 4px;
}
.gi-assets-center .assets-card {
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: 30px;
  border-radius: 2150px !important;
  margin-bottom: 4px;
  border-width: 2px !important;
}
.gi-assets-center .assets-card .ant-pro-checkcard-avatar {
  padding-right: 0;
  width: 40px !important;
  height: 40px !important;
  line-height: 36px !important;
  font-size: 20px;
}
.gi-assets-center .assets-card .ant-pro-checkcard-avatar .ant-avatar-circle {
  background: unset !important;
}
.gi-assets-center .assets-card .ant-pro-checkcard-detail {
  height: 40px;
  line-height: 40px;
}
.gi-assets-center .assets-card .ant-pro-checkcard-header {
  display: block;
}
.gi-assets-center .assets-card .ant-pro-checkcard-header .ant-pro-checkcard-title {
  font-size: 12px;
}
.gi-assets-center .assets-card .ant-pro-checkcard-avatar-header {
  display: inline-flex;
  text-align: center;
}
.gi-assets-center .ant-pro-checkcard-multiple.ant-pro-checkcard-checked {
  border-width: 2px !important;
  border-style: solid !important;
  box-shadow: unset !important;
}
.gi-assets-center .ant-pro-checkcard-multiple.ant-pro-checkcard-checked::after {
  position: absolute;
  top: 9px;
  left: 4px;
  width: 8px;
  height: 8px;
  background: #3056e3;
  border: unset;
  border-radius: 50%;
  content: '';
}
.gi-assets-center .gi-asset-center-tag {
  font-size: 12px;
  transform: scale(0.8);
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.gi-assets-center .gi-assets-center-filter-wrapper {
  margin-bottom: 12px;
}
.gi-assets-center .gi-assets-center-filter-wrapper .gi-asset-center-tag-filter {
  display: inline-flex;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.gi-assets-center .gi-assets-center-tooltip {
  position: absolute;
  bottom: calc(50vh - 126px);
  transform: translateY(100%);
  margin-left: calc(100% - 28px);
  background: var(--background-color);
  width: 150px;
  height: 200px;
  text-align: center;
  padding: 16px 8px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transition: all 0.3s ease;
}
