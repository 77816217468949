.gi-site-home .greeting {
  display: block;
  padding: 12px;
  color: var(--text-color);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.7px;
  margin-bottom: 0.5em;
  height: 100%;
}
.gi-site-home .container {
  display: flex;
  /** 52px is the height of greeting line; 100px is the sum of header height and bottom margin */
  height: calc(100vh - 52px - 100px);
  flex-direction: row;
  background: transparent;
}
.gi-site-home .flex-left {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding-right: 12px;
}
.gi-site-home .flex-right {
  display: flex;
  flex-direction: column;
  flex-basis: 270px;
}
