.gi-properties-pannel {
  background: var(--background-color);
  color: var(--text-color);
}
.gi-properties-pannel .gi-properties-pannel-header {
  display: flex;
  justify-content: space-between;
}
.gi-properties-pannel .gi-properties-pannel-header h3 {
  color: var(--text-color);
}
.gi-properties-pannel ul {
  display: block;
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.gi-properties-pannel ul li {
  padding: 6px 0px;
}
.gi-properties-pannel ul li .key {
  font-size: 12px;
  opacity: 0.8;
}
.gi-properties-pannel ul li .value {
  font-size: 14px;
  word-break: break-word;
}
