.gi-json-mode {
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.gi-json-mode .ant-tabs {
  height: 100%;
}
.gi-json-mode .ant-tabs .ant-tabs-content {
  height: 100%;
}
.gi-json-mode .ant-tabs-tabpane {
  height: 100%;
  width: 100%;
}
.gi-json-mode:-webkit-full-screen {
  background-color: #fff;
}
.gi-json-mode:fullscreen {
  background-color: #fff;
}
