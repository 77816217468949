.version-container {
  margin-top: 20px;
}
.detail-container {
  height: 100%;
  position: relative;
}
.detail-container .header {
  position: relative;
  display: flex;
  justify-content: center;
}
.detail-container .header .ant-btn {
  position: absolute;
  left: 2px;
}
