.dialog-content::-webkit-scrollbar {
  display: none;
}
.dialog-content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  color: white;
}
.dialog-content .list::-webkit-scrollbar {
  display: none;
}
.dialog-content .list {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  overflow: scroll;
}
.dialog-content .list .message {
  max-width: 80%;
}
.dialog-content .list .message .message-content {
  padding: 10px 16px;
  font-size: 14px !important;
  border-radius: 5px;
}
.dialog-content .list .message .message-content:not(.wmde-markdown) {
  white-space: pre-line;
}
.dialog-content .list .user {
  margin-left: auto;
}
.dialog-content .list .user .message-content {
  background-color: var(--primary-color);
}
.dialog-content .list .user .wmde-markdown p,
.dialog-content .list .user .wmde-markdown pre {
  margin: 0 !important;
  color: white;
}
.dialog-content .list .assistant {
  margin-right: auto;
}
.dialog-content .input-container {
  display: flex;
  align-items: center;
  padding: 5px;
}
.dialog-content .input-container .ant-spin-nested-loading {
  width: 100%;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.dialog-content .input-container .stop-query {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50px);
}
.dialog-content .input-container .new-dialog {
  margin: 0 10px;
  font-size: 20px;
}
