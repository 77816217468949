.gi-collapse-card {
  margin: 8px;
}
.gi-collapse-card .ant-collapse {
  box-sizing: border-box;
  width: 100%;
  background-color: var(--component-background);
  border: none;
  border-radius: 8px;
  box-shadow: var(--box-shadow-pannel);
}
.gi-collapse-card .ant-collapse .ant-collapse-item {
  padding: 4px;
  border: none;
  background: var(--background-color);
}
.gi-collapse-card .ant-collapse-content {
  padding: 4px;
  border: none;
}
